<template>
  <div>
    <CyDetails
      :loading="false"
      :hide-delete="$isCreationRoute"
      :deleting="deleting"
      :on-delete="$toggle.showDeleteModal"
      :can-cancel="canCancel"
      :on-cancel="onCancel"
      :can-save="canSave"
      :on-save="onSave"
      :saving="saving">
      <template slot="details_formFullWidth">
        <div class="form-section mb-8">
          <div class="form-section__description">
            <h3 v-text="$t('scope')"/>
          </div>
          <div class="form-section__inputs">
            <v-autocomplete
              v-model="$v.formContent.team_canonical.$model"
              class="team-input required-field mb-4"
              :error-messages="inputErrors.team_canonical"
              :disabled="!$isCreationRoute"
              :hint="$t('hints.team')"
              :items="teams"
              :label="$t('Team')"
              :loading="loading"
              item-text="name"
              item-value="canonical"
              persistent-hint
              required
              @blur="$v.formContent.team_canonical.$touch()">
              <template #selection="{ item }">
                <CyTag variant="default">
                  {{ item.name }}
                </CyTag>
              </template>
            </v-autocomplete>
            <v-autocomplete
              v-model="$v.formContent.resource_pool_canonical.$model"
              class="resource-pool-input required-field"
              :error-messages="inputErrors.resource_pool_canonical"
              :disabled="!$isCreationRoute"
              :hint="$t('hints.resourcePool')"
              :items="resourcePools"
              :label="$t('ResourcePool')"
              :loading="loading"
              item-text="name"
              item-value="canonical"
              persistent-hint
              required
              @blur="$v.formContent.resource_pool_canonical.$touch()"
              @input="SET_RESOURCE_POOL(_.find(resourcePools, ['canonical', $event]) || null)">
              <template #selection="{ item }">
                <CyTag variant="default">
                  {{ item.name }}
                </CyTag>
              </template>
              <template #no-data>
                <div
                  v-if="_.isEmpty(resourcePools)"
                  class="resource-pool-input__no-data px-4 py-1">
                  <div class="resource-pool-input__no-data-title">
                    {{ $t('noData.title') }}
                  </div>
                  <span class="resource-pool-input__no-data-text">
                    {{ $t('noData.text') }}
                  </span>
                  <CyButton
                    class="create-resource-pool__btn mt-4"
                    icon="add"
                    theme="primary"
                    variant="secondary"
                    :to="{ name: 'newResourcePool' }">
                    {{ $t('quotas.addResourcePool') }}
                  </CyButton>
                </div>
                <div
                  v-else
                  class="resource-pool-input__no-data px-4 py-1">
                  <div class="resource-pool-input__no-data-title">
                    {{ $t('notFound.title') }}
                  </div>
                  <span class="resource-pool-input__no-data-text">
                    {{ $t('notFound.text') }}
                  </span>
                </div>
              </template>
            </v-autocomplete>
          </div>
          <div class="form-section__info"/>
        </div>
        <v-divider class="partial-divider my-8 ml-6"/>
        <div class="form-section">
          <div class="form-section__description">
            <h3
              class="mb-4"
              v-text="$t('untranslated.quotas')"/>
            <div v-html="$sanitizeHtml($t('quotasSectionDescription'))"/>
          </div>
          <div class="form-section__inputs">
            <CyInputsStorage
              v-model="$v.formContent.memory.$model"
              class="memory-input mb-6"
              :label="$t('quotas.memory')"
              :loading="loading"
              :error-messages="inputErrors.memory"
              required
              @blur="$v.formContent.memory.$touch()"/>
            <CyInputsStorage
              v-model="$v.formContent.storage.$model"
              class="storage-input mb-6"
              :label="$t('quotas.storage')"
              :loading="loading"
              :error-messages="inputErrors.storage"
              required
              @blur="$v.formContent.storage.$touch()"/>
            <v-text-field
              v-model.number="$v.formContent.cpu.$model"
              class="cpu-input required-field mb-6"
              :label="$t('quotas.cpuCores')"
              :loading="loading"
              :error-messages="inputErrors.cpu"
              type="number"
              required
              @blur="$v.formContent.cpu.$touch()"/>
          </div>
          <div
            v-if="resourcePool"
            class="form-section__info pl-8">
            <div class="resource-pool-capacity">
              <h3
                class="mb-4"
                v-text="$t('resourcePoolCapacity')"/>
              <div class="memory-capacity mb-6">
                <h4 v-text="$t('quotas.memory')"/>
                <div v-text="`${formatBytes(unallocated.memory)} ${$t('quotas.unallocated').toLowerCase()}`"/>
                <div v-text="`${formatBytes(resourcePool.memory)} ${$t('untranslated.total').toLowerCase()}`"/>
              </div>
              <div class="storage-capacity mb-6">
                <h4 v-text="$t('quotas.storage')"/>
                <div v-text="`${formatBytes(unallocated.storage)} ${$t('quotas.unallocated').toLowerCase()}`"/>
                <div v-text="`${formatBytes(resourcePool.storage)} ${$t('untranslated.total').toLowerCase()}`"/>
              </div>
              <div class="cpu-capacity mb-6">
                <h4 v-text="$t('quotas.cpu')"/>
                <div v-text="`${$tc('quotas.nCores', unallocated.cpu)} ${$t('quotas.unallocated').toLowerCase()}`"/>
                <div v-text="`${$tc('quotas.nCores', resourcePool.cpu)} ${$t('untranslated.total').toLowerCase()}`"/>
              </div>
            </div>
          </div>
        </div>
        <v-divider class="partial-divider my-8 ml-6"/>
        <div class="form-section d-flex flex-wrap">
          <div class="form-section__description mb-8">
            <h3
              class="mb-4"
              v-text="$t('quotas.usage')"/>
            <div v-text="$t('usageSectionDescription')"/>
          </div>
          <CyQuotasUsageTable
            :quota="quota"
            :projects="usage"
            :loading="loading"/>
        </div>
      </template>
    </CyDetails>

    <CyModal
      v-if="showDeleteModal"
      :header-title="$t('confirmDeleteHeader')"
      :action-btn-func="onDelete"
      :cancel-btn-func="() => $toggle.showDeleteModal(false)"
      modal-type="delete"
      small>
      <p v-html="$sanitizeHtml($t('confirmDeleteSubtitle', { team: teamName, resourcePool: resourcePoolName }))"/>
      <p v-text="$t('confirmDeleteSentence')"/>
    </CyModal>
  </div>
</template>

<script>
import CyDetails from '@/components/details'
import CyInputsStorage from '@/components/inputs/storage.vue'
import CyQuotasUsageTable from '@/components/quotas/usage-table'
import { constructBreadcrumb } from '@/utils/helpers'
import { required, maxValue, integer, minValue } from 'vuelidate/lib/validators'
import { mapActions, mapState, mapMutations } from 'vuex'
import { formatBytes } from '@/utils/helpers/quotas'

export default {
  name: 'CyPageQuota',
  components: {
    CyDetails,
    CyInputsStorage,
    CyQuotasUsageTable,
  },
  breadcrumb () {
    const header = this.$t(this.$isCreationRoute ? 'createNewQuota' : 'editQuota')
    return constructBreadcrumb(this.$options.name, header, [
      {
        label: this.$t('routes.quotas'),
        name: 'quotas',
      },
      {
        label: this.$t('routes.resourcesSection'),
        name: 'resourcesSection',
      },
    ])
  },
  header () {
    return {
      title: this.$isCreationRoute ? this.$t('quotas.createQuota') : this.$t('editQuota'),
      description: {
        text: this.$t('subtitle'),
        link: $docLinks.quotas.index,
      },
    }
  },
  props: {
    quotaId: {
      type: [Number, String],
      default: '',
    },
  },
  validations () {
    return {
      formContent: {
        team_canonical: { required },
        resource_pool_canonical: { required },
        memory: { required, minValue: minValue(0), maxValue: maxValue(this.unallocated.memory) },
        storage: { required, minValue: minValue(0), maxValue: maxValue(this.unallocated.storage) },
        cpu: { required, integer, minValue: minValue(0), maxValue: maxValue(this.unallocated.cpu) },
      },
    }
  },
  data: () => ({
    deleting: false,
    loading: false,
    saving: false,
    showDeleteModal: false,
    formContent: {
      team_canonical: null,
      resource_pool_canonical: null,
      memory: null,
      storage: null,
      cpu: null,
    },
  }),
  computed: {
    ...mapState('organization', {
      teams: (state) => state.available.teams,
      resourcePools: (state) => state.available.resourcePools,
    }),
    ...mapState('organization/quota', {
      quota: (state) => state.detail,
      usage: (state) => state.usage,
      errors: (state) => state.errors.quota,
    }),
    ...mapState('organization/resourcePool', {
      resourcePool: (state) => state.detail,
    }),
    inputErrors () {
      const scopeInputs = ['team_canonical', 'resource_pool_canonical']
      const scopeErrors = scopeInputs.reduce((acc, inputType) => {
        const errors = []
        const { $dirty, required } = this.$v.formContent[inputType]
        if (!$dirty) return { ...acc, [inputType]: [] }
        if (!required) errors.push(this.$t('forms.fieldRequired'))
        return { ...acc, [inputType]: errors }
      }, {})

      const quotaInputs = ['memory', 'storage', 'cpu']
      const quotaErrors = quotaInputs.reduce((acc, inputType) => {
        const errors = []
        const { $dirty, required, integer, minValue } = this.$v.formContent[inputType]
        if (!$dirty) return { ...acc, [inputType]: [] }
        if (!required) errors.push(this.$t('forms.fieldRequired'))
        if (!minValue) errors.push(this.$t('quotas.positiveOrZero'))
        if (!integer && inputType === 'cpu') errors.push(this.$t('quotas.integer'))
        if (this.unallocated[inputType] < this.formContent[inputType]) errors.push(this.$t('insufficientResource', { type: this.$t(`quotas.${inputType}`).toLowerCase() }))
        return { ...acc, [inputType]: errors }
      }, {})

      return { ...scopeErrors, ...quotaErrors }
    },
    canCancel () {
      return _.some(_.keys(this.formContent)
        .map((key) => this.$hasDataChanged(`formContent.${key}`)))
    },
    canSave () {
      const { $v, canCancel } = this
      return canCancel && !$v.$invalid
    },
    teamName () {
      return _.find(this.teams, ['canonical', this.quota?.team.canonical])?.name || this.quota?.team_canonical
    },
    resourcePoolName () {
      return _.find(this.resourcePools, ['canonical', this.quota?.resource_pool.canonical])?.name || this.quota?.resource_pool_canonical
    },
    unallocated () {
      return ['memory', 'storage', 'cpu'].reduce((acc, it) => {
        const unallocated = _.get(this.resourcePool, it, 0) - _.get(this.resourcePool, `allocated_${it}`, 0)
        return { ...acc, [it]: unallocated + _.get(this.quota, it, 0) }
      }, {})
    },
  },
  created () {
    this.FETCH_AVAILABLE({ keyPath: 'teams' })
    this.FETCH_AVAILABLE({ keyPath: 'resourcePools' })
    if (!this.$isCreationRoute) this.fetchQuotaData()
  },
  destroyed () {
    this.RESET_QUOTA_STATE()
    this.RESET_RESOURCE_POOL_STATE()
  },
  methods: {
    ...mapActions('alerts', [
      'SHOW_ALERT',
    ]),
    ...mapActions('organization', [
      'FETCH_AVAILABLE',
    ]),
    ...mapActions('organization/quota', [
      'CREATE_QUOTA',
      'UPDATE_QUOTA',
      'GET_QUOTA',
      'DELETE_QUOTA',
      'GET_QUOTA_USAGE',
    ]),
    ...mapActions('organization/resourcePool', [
      'GET_RESOURCE_POOL',
    ]),
    ...mapMutations('organization/resourcePool', [
      'RESET_RESOURCE_POOL_STATE',
      'SET_RESOURCE_POOL',
    ]),
    ...mapMutations('organization/quota', [
      'RESET_QUOTA_STATE',
    ]),
    formatBytes,
    onCancel () {
      this.$resetData('formContent')
      this.$v.$reset()
    },
    async onSave () {
      this.$toggle.saving(true)

      const { $router, $isCreationRoute } = this
      const quota = _.cloneDeep(this.formContent)

      $isCreationRoute
        ? await this.CREATE_QUOTA({ $router, quota })
        : await this.UPDATE_QUOTA({ $router, quota: _.pick(quota, ['id', 'memory', 'storage', 'cpu']) })

      if (!_.isEmpty(this.errors)) this.SHOW_ALERT({ type: 'error', content: this.errors })
      else this.$setOriginalData()

      this.$toggle.saving(false)
    },
    async onDelete () {
      this.$toggle.deleting(true)
      const { $router } = this
      await this.DELETE_QUOTA({ quotaId: this.quotaId, $router })
      this.$toggle.showDeleteModal(false)
      this.$toggle.deleting(false)
    },
    async fetchQuotaData () {
      this.$toggle.loading(true)
      await Promise.all([
        this.GET_QUOTA({ quotaId: this.quotaId }),
        this.GET_QUOTA_USAGE({ quotaId: this.quotaId }),
      ])
      if (this.quota) {
        this.formContent = _.cloneDeep({
          ..._.omit(this.quota, ['team', 'resource_pool']),
          team_canonical: this.quota.team?.canonical,
          resource_pool_canonical: this.quota.resource_pool?.canonical,
        })
        this.$setOriginalData()
        await this.GET_RESOURCE_POOL({ resourcePoolCanonical: this.quota.resource_pool?.canonical })
      }
      this.$toggle.loading(false)
    },
  },
  i18n: {
    messages: {
      en: {
        title: '@:routes.quota',
        createNewQuota: 'Create a new @:Quota',
        editQuota: 'Edit @:Quota',
        scope: 'Scope',
        hints: {
          team: 'The team to assign the quota to.',
          resourcePool: 'The resource pool from which to consume resources.',
        },
        noData: {
          title: 'No @:resourcePools found.',
          text: 'Add @:resourcePools before you can select one here.',
        },
        notFound: {
          title: '@:forms.noResults',
          text: 'There is no @:resourcePool matching this search.',
        },
        insufficientResource: 'The selected resource pool does not have sufficient unallocated {type} capacity',
        subtitle: 'Use quotas to limit how much Memory, Storage and CPU a team can use.',
        quotasSectionDescription: 'For each quota item, specify the available resource limit.</br>Users won’t be able to perform actions that would cause a team quota to be exceeded.',
        usageSectionDescription: 'Understand team quota usage and see which projects are the most resource-intensive.',
        confirmDeleteHeader: 'Delete quota?',
        confirmDeleteSubtitle: 'Memory, Storage and CPU quotas are going to be deleted for <b>{team}</b> team on the <b>{resourcePool}</b> resource pool.',
        confirmDeleteSentence: 'This operation might fail if some projects are consuming this quota. Transfer the ownership of project to another team or delete them and try again.',
        resourcePoolCapacity: 'Resource pool capacity',
      },
      es: {
        title: '@:routes.quota',
        createNewQuota: 'Crear una nueva @:Quota',
        editQuota: 'Editar @:Quota',
        scope: 'Alcance',
        hints: {
          team: 'El equipo al que se va a asignar la quota.',
          resourcePool: 'El grupo de recursos del que consumir recursos.',
        },
        noData: {
          title: 'No se encontraron @:resourcePools .',
          text: 'Agregue @:resourcePools antes de poder seleccionar uno aquí.',
        },
        notFound: {
          title: '@:forms.noResults',
          text: 'No hay ningún @:resourcePool que coincida con esta búsqueda.',
        },
        insufficientResource: 'El grupo de recursos seleccionado no tiene suficiente capacidad de {type} sin asignar',
        subtitle: 'Use quotas para limitar la cantidad de memoria, almacenamiento y CPU que puede usar un equipo.',
        quotasSectionDescription: 'Para cada elemento de la cuota, especifique el límite de recursos disponibles.</br>Los usuarios no podrán realizar acciones que hagan que se exceda la cuota de un equipo.',
        usageSectionDescription: 'Comprenda el uso de la quota del equipo y vea qué proyectos requieren más recursos.',
        confirmDeleteHeader: 'Eliminar quota?',
        confirmDeleteSubtitle: 'Las quotas de memoria, almacenamiento y CPU se eliminarán para el equipo <b>{team}</b> en el grupo de recursos <b>{resourcePool}</b>.',
        confirmDeleteSentence: 'Esta operación puede fallar si algunos proyectos consumen esta cuota. Transfiera la propiedad del proyecto a otro equipo o elimínelo y vuelva a intentarlo.',
        resourcePoolCapacity: 'Capacidad del grupo de recursos',
      },
      fr: {
        title: '@:routes.quota',
        createNewQuota: 'Créer une nouvelle @:Quota',
        editQuota: 'Editer un @:Quota',
        scope: 'Portée',
        hints: {
          team: `L'équipe à laquelle assigner le quota.`,
          resourcePool: 'Le pool de resources a partir duquel consommer les ressources.',
        },
        noData: {
          title: 'Aucun @:resourcePools trouvé.',
          text: 'Ajoutez des @:resourcePools avant de pouvoir en sélectionner un ici.',
        },
        notFound: {
          title: '@:forms.noResults',
          text: 'Aucun @:resourcePool ne correspond à cette recherche.',
        },
        insufficientResource: `Le pool de resources sélectioné n'a pas une capacité suffisante de {type} non allouée`,
        subtitle: `Utilisez des quotas pour limiter la quantité de mémoire, de stockage et de CPU qu'une équipe peut utiliser.`,
        quotasSectionDescription: `Pour chaque élément de quota, spécifiez la limite de ressource disponible.</br>Les utilisateurs ne pourront pas effectuer d'actions qui entraîneraient le dépassement du quota d'une équipe.`,
        usageSectionDescription: `Comprendre l'utilisation des quotas de l'équipe et voir quels projets sont les plus gourmands en ressources.`,
        confirmDeleteHeader: 'Supprimer le quota ?',
        confirmDeleteSubtitle: `Les quotas de mémoire, stockage et CPU vont être supprimés pour l'équipe <b>{team}</b> sur le pool de ressources <b>{resourcePool}</b>.`,
        confirmDeleteSentence: 'Cette opération peut échouer si des projets consomment ce quota. Transferez la possession des projets vers une autre équipe ou supprimez-les, puis essayez à nouveau.',
        resourcePoolCapacity: 'Capacité du pool de ressources',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .cy-details {
  &__content {
    @extend %cy-scrollbars;

    padding: 0;
  }

  &__section {
    &-contained-wrapper {
      display: none;
    }
  }
}

.partial-divider {
  width: 916px;
}

.form-section {
  display: flex;
  padding: 0 24px;

  &__description {
    width: 340px;
    margin-right: 32px;
  }

  &__inputs {
    flex-grow: 1;
    min-width: 300px;
    max-width: 520px;
  }

  &__info {
    min-width: 180px;
  }
}

.resource-pool-input {
  &__no-data {
    &-title {
      font-weight: $font-weight-bold;
    }

    &-title,
    &-text {
      display: block;
      color: get-color("primary");
    }
  }
}

h2 {
  color: get-color("primary");
}
</style>
